import { ControlAlgorithmConfigs } from "@apis/types";

export const CONTROL_ALGORITHM_KEY = {
  "AUTO_ADJUST_ENABLED": "autoAdjustEnabled",
  "WIT": "WIT",
  "DCT": "DCT",
  "EFM": "EFM",
  "OPEN_MASTER_GAIN": "openMasterGain",
  "CLOSE_MASTER_GAIN": "closeMasterGain",
  "MIN_OPEN_VALVE_STEP": "minOpenValveStep",
  "MIN_CLOSE_VALVE_STEP": "minCloseValveStep",
  "MAX_OPEN_VALVE_STEP": "maxOpenValveStep",
  "MAX_CLOSE_VALVE_STEP": "maxCloseValveStep",
  "EFM_VALVE_STEP": "EFMValveStep",
  "GAS_TEMP_MAX": "gasTempMax",
  "CH4_RANGE": "CH4Range",
  "O2_RANGE": "O2Range",
  "BAL_RANGE": "balRange",
  "STATIC_PRESSURE_RANGE": "staticPressureRange",
  "SYSTEM_PRESSURE_RANGE": "systemPressureRange",
  "FLOW_RANGE": "flowRange",
  "VALVE_RANGE": "valveRange",
  "PRESSURE_CONTROL_ENABLED": "pressureControlEnabled",
  "PRESSURE_CONTROL_VALUE": "pressureControlValue",
  "CH4_DELTA_STOP": "ch4DeltaStop",
  "INFLOW_CONTROL": "inFlowControl",
  "DAY_RANGE": "dayRange",
} as const
export const CONTROL_ALGORITHM_BOOL_KEYS = [
  CONTROL_ALGORITHM_KEY.AUTO_ADJUST_ENABLED,
  CONTROL_ALGORITHM_KEY.WIT,
  CONTROL_ALGORITHM_KEY.DCT,
  CONTROL_ALGORITHM_KEY.EFM,
  CONTROL_ALGORITHM_KEY.PRESSURE_CONTROL_ENABLED,
  CONTROL_ALGORITHM_KEY.INFLOW_CONTROL
] as const
export const CONTROL_ALGORITHM_NUMBER_KEYS = [
  CONTROL_ALGORITHM_KEY.OPEN_MASTER_GAIN,
  CONTROL_ALGORITHM_KEY.CLOSE_MASTER_GAIN,
  CONTROL_ALGORITHM_KEY.MIN_OPEN_VALVE_STEP,
  CONTROL_ALGORITHM_KEY.MIN_CLOSE_VALVE_STEP,
  CONTROL_ALGORITHM_KEY.MAX_OPEN_VALVE_STEP,
  CONTROL_ALGORITHM_KEY.MAX_CLOSE_VALVE_STEP,
  CONTROL_ALGORITHM_KEY.EFM_VALVE_STEP,
  CONTROL_ALGORITHM_KEY.GAS_TEMP_MAX,
  CONTROL_ALGORITHM_KEY.PRESSURE_CONTROL_VALUE,
  CONTROL_ALGORITHM_KEY.CH4_DELTA_STOP,
  CONTROL_ALGORITHM_KEY.DAY_RANGE
] as const
export const CONTROL_ALGORITHM_RANGE_KEYS = [
  CONTROL_ALGORITHM_KEY.CH4_RANGE,
  CONTROL_ALGORITHM_KEY.O2_RANGE,
  CONTROL_ALGORITHM_KEY.BAL_RANGE,
  CONTROL_ALGORITHM_KEY.STATIC_PRESSURE_RANGE,
  CONTROL_ALGORITHM_KEY.FLOW_RANGE,
  CONTROL_ALGORITHM_KEY.VALVE_RANGE,
  CONTROL_ALGORITHM_KEY.SYSTEM_PRESSURE_RANGE
] as const
export const CONTROL_ALGORITHM_KEYS = [
  // Order must be same with CONTROL_ALGORITHM_ATTRIBUTES
  CONTROL_ALGORITHM_KEY.AUTO_ADJUST_ENABLED,
  CONTROL_ALGORITHM_KEY.WIT,
  CONTROL_ALGORITHM_KEY.DCT,
  CONTROL_ALGORITHM_KEY.EFM,
  CONTROL_ALGORITHM_KEY.OPEN_MASTER_GAIN,
  CONTROL_ALGORITHM_KEY.CLOSE_MASTER_GAIN,
  CONTROL_ALGORITHM_KEY.CH4_RANGE,
  CONTROL_ALGORITHM_KEY.O2_RANGE,
  CONTROL_ALGORITHM_KEY.BAL_RANGE,
  CONTROL_ALGORITHM_KEY.STATIC_PRESSURE_RANGE,
  CONTROL_ALGORITHM_KEY.SYSTEM_PRESSURE_RANGE,
  CONTROL_ALGORITHM_KEY.FLOW_RANGE,
  CONTROL_ALGORITHM_KEY.GAS_TEMP_MAX,
  CONTROL_ALGORITHM_KEY.VALVE_RANGE,
  CONTROL_ALGORITHM_KEY.MIN_OPEN_VALVE_STEP,
  CONTROL_ALGORITHM_KEY.MIN_CLOSE_VALVE_STEP,
  CONTROL_ALGORITHM_KEY.MAX_OPEN_VALVE_STEP,
  CONTROL_ALGORITHM_KEY.MAX_CLOSE_VALVE_STEP,
  CONTROL_ALGORITHM_KEY.EFM_VALVE_STEP,
  CONTROL_ALGORITHM_KEY.PRESSURE_CONTROL_ENABLED,
  CONTROL_ALGORITHM_KEY.PRESSURE_CONTROL_VALUE,
  CONTROL_ALGORITHM_KEY.CH4_DELTA_STOP,
  CONTROL_ALGORITHM_KEY.INFLOW_CONTROL,
  CONTROL_ALGORITHM_KEY.DAY_RANGE
] as const

export const CONTROL_ALGORITHM_PRIORITY_OPTION = {
  "CH4": "CH4",
  "O2": "O2",
  "BAL": "bal",
  "STATIC_PRESSURE": "staticPressure",
  "GAS_TEMP": "gasTemp"
} as const

export const CONTROL_ALGORITHM_PRIORITY_OPTIONS = [
  CONTROL_ALGORITHM_PRIORITY_OPTION.CH4,
  CONTROL_ALGORITHM_PRIORITY_OPTION.O2,
  CONTROL_ALGORITHM_PRIORITY_OPTION.BAL,
  CONTROL_ALGORITHM_PRIORITY_OPTION.STATIC_PRESSURE,
  CONTROL_ALGORITHM_PRIORITY_OPTION.GAS_TEMP
] as const

export const CONTROL_ALGORITHM_ATTRIBUTES: ControlAlgorithmConfigs = {
  autoAdjustEnabled: {
    label: "Auto Adjust Enabled",
    type: "bool",
    defaultValue: false
  },
  WIT: {
    label: "WIT Enabled",
    type: "bool",
    defaultValue: false
  },
  DCT: {
    label: "DCT Enabled",
    type: "bool",
    defaultValue: false
  },
  EFM: {
    label: "EFM Enabled",
    type: "bool",
    defaultValue: true
  },
  openMasterGain: {
    label: "Open Master Gain",
    type: "number",
    defaultValue: 0.5,
    min: 0,
    max: 2,
    unit: "0-2",
    precision: 2
  },
  closeMasterGain: {
    label: "Close Master Gain",
    type: "number",
    defaultValue: 0.5,
    min: 0,
    max: 2,
    unit: "0-2",
    precision: 2
  },
  CH4Range: {
    label: "CH4 Range",
    type: "range",
    defaultValue: { min: 48, max: 52 },
    min: 0,
    max: 75,
    unit: "%"
  },
  O2Range: {
    label: "O2 Range",
    type: "range",
    defaultValue: { min: 0, max: 2.5 },
    min: 0,
    max: 20,
    unit: "%"
  },
  balRange: {
    label: "Balance Range",
    type: "range",
    defaultValue: { min: 0, max: 15 },
    min: 0,
    max: 100,
    unit: "%"
  },
  staticPressureRange: {
    label: "Static Pressure Range",
    type: "range",
    defaultValue: { min: -100, max: 0 },
    min: -100,
    max: 50,
    unit: "in. wc"
  },
  systemPressureRange: {
    label: "System Pressure Range",
    type: "range",
    defaultValue: { min: -100, max: 0 },
    min: -100,
    max: 0,
    unit: "in. w.c."
  },
  flowRange: {
    label: "Flow Range",
    type: "range",
    defaultValue: { min: 0, max: 200 },
    min: 0,
    max: 500,
    unit: "CFM"
  },
  gasTempMax: {
    label: "Gas Temp Max",
    type: "number",
    defaultValue: 250,
    min: 0,
    max: 250,
    unit: "degF",
    precision: 1
  },
  valveRange: {
    label: "Valve Range",
    type: "range",
    defaultValue: { min: 0, max: 100 },
    min: 0,
    max: 100,
    unit: "%"
  },
  minOpenValveStep: {
    label: "Min Open Valve Step",
    type: "number",
    defaultValue: 1,
    min: 0.125,
    max: 10,
    unit: "%",
    precision: 3
  },
  minCloseValveStep: {
    label: "Min Close Valve Step",
    type: "number",
    defaultValue: 1,
    min: 0.125,
    max: 10,
    unit: "%",
    precision: 3
  },
  maxOpenValveStep: {
    label: "Max Open Valve Step",
    type: "number",
    defaultValue: 10,
    min: 0,
    max: 100,
    unit: "%",
    precision: 1
  },
  maxCloseValveStep: {
    label: "Max Close Valve Step",
    type: "number",
    defaultValue: 10,
    min: 0,
    max: 100,
    unit: "%",
    precision: 1
  },
  EFMValveStep: {
    label: "EFM Valve Step",
    type: "number",
    defaultValue: 5,
    min: 0,
    max: 100,
    unit: "%",
    precision: 1
  },
  pressureControlEnabled: {
    label: "Pressure Control Enabled",
    type: "bool",
    defaultValue: false,
  },
  pressureControlValue: {
    label: "Pressure Control Value",
    type: "number",
    defaultValue: 0,
    min: -100,
    max: 0,
    unit: "in. w.c.",
    precision: 1
  },
  ch4DeltaStop: {
    label: "CH4 Delta Stop",
    type: "number",
    defaultValue: 25,
    min: 0,
    max: 100,
    unit: "%",
    precision: 1
  },
  inFlowControl: {
    label: "inFlow Control",
    type: "bool",
    defaultValue: true,
  },
  dayRange: {
    label: "Day Range",
    type: "number",
    defaultValue: 3,
    min: 1,
    max: 7,
    unit: "days",
    precision: 0
  }
}

export const CONTROL_ALGORITHM_LIMITER = {
  NO: 0,
  MAX_OPEN_VALVE_STEP: 1,
  MIN_OPEN_VALVE_STEP: 2,
  MAX_CLOSE_VALVE_STEP: 3,
  MIN_CLOSE_VALVE_STEP: 4,
  UPPER_RANGE_LIMIT: 5,
  LOWER_RANGE_LIMIT: 6
}

export const CONTROL_ALGORITHM_LIMITERS = [
  CONTROL_ALGORITHM_LIMITER.NO,
  CONTROL_ALGORITHM_LIMITER.MAX_OPEN_VALVE_STEP,
  CONTROL_ALGORITHM_LIMITER.MIN_OPEN_VALVE_STEP,
  CONTROL_ALGORITHM_LIMITER.MAX_CLOSE_VALVE_STEP,
  CONTROL_ALGORITHM_LIMITER.MIN_CLOSE_VALVE_STEP,
  CONTROL_ALGORITHM_LIMITER.UPPER_RANGE_LIMIT,
  CONTROL_ALGORITHM_LIMITER.LOWER_RANGE_LIMIT,
]
