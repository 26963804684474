import { useMutation, useQuery, UseQueryOptions, UseMutationOptions } from "@tanstack/react-query";
import * as DeviceGroupRequests from "./service/deviceGroup";
import { CreateDeviceGroupRequest, CurrentData, DeviceGroupT, LandfillCharts, LandfillControlAlgorithmConfigs } from "@apis/types";
import { queryClient } from "./index";

////////// QUERIES //////////
export const useGetDeviceGroup = (deviceGroupID: string, options?: Omit<UseQueryOptions<DeviceGroupT>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-group", deviceGroupID],
  queryFn: () => DeviceGroupRequests.getDeviceGroup(deviceGroupID),
  ...options
});

export const useGetLandfillDeviceGroups = (landfillID: string, options?: Omit<UseQueryOptions<DeviceGroupT[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["landfill-device-groups", landfillID],
  queryFn: () => DeviceGroupRequests.getDeviceGroupsOfLandfill(landfillID),
  ...options
});

export const useGetDeviceGroupCurrentData = (deviceGroupID: string, options?: Omit<UseQueryOptions<CurrentData[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-group-current-data", deviceGroupID],
  queryFn: () => DeviceGroupRequests.getDeviceGroupCurrentData(deviceGroupID),
  ...options
});

export const useGetDeviceGroupCharts = (deviceGroupID: string, start: string, end: string, options?: Omit<UseQueryOptions<LandfillCharts>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-group-charts", deviceGroupID, start, end],
  queryFn: () => DeviceGroupRequests.getDeviceGroupCharts(deviceGroupID, start, end),
  ...options
});



////////// MUTATIONS //////////
export const useCreateDeviceGroup = (landfillID: string, options?: UseMutationOptions<DeviceGroupT, Error, CreateDeviceGroupRequest>) => useMutation({
  mutationKey: ["create-device-group", landfillID],
  mutationFn: (body) => DeviceGroupRequests.createDeviceGroup(landfillID, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-device-groups", landfillID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useAddDeviceToGroup = (deviceGroupID: string, options?: UseMutationOptions<DeviceGroupT, Error, string>) => useMutation({
  mutationKey: ["add-device-to-group", deviceGroupID],
  mutationFn: (d) => DeviceGroupRequests.addDeviceToGroup(deviceGroupID, d),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["device-group", deviceGroupID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useTakeSampleDevicesOfGroup = (deviceGroupID: string, options?: UseMutationOptions<{ failed: string[] }>) => useMutation({
  mutationKey: [deviceGroupID, "sample-all"],
  mutationFn: () => DeviceGroupRequests.sampleAllDevicesInGroup(deviceGroupID),
  ...options
})

export const useRemoveDeviceFromGroup = (deviceGroupID: string, options?: UseMutationOptions<DeviceGroupT, Error, string>) => useMutation({
  mutationKey: ["remove-device-from-group", deviceGroupID],
  mutationFn: (d) => DeviceGroupRequests.removeDeviceFromGroup(deviceGroupID, d),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["device-group", deviceGroupID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useDeleteDeviceGroup = (deviceGroupID: string, landfillID: string, options?: UseMutationOptions<{ message: string }>) => useMutation({
  mutationKey: ["delete-device-group", deviceGroupID],
  mutationFn: () => DeviceGroupRequests.deleteDeviceGroup(deviceGroupID),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-device-groups", landfillID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useSetMainDeviceGroup = (deviceGroupID: string, options?: UseMutationOptions<DeviceGroupT, Error, string>) => useMutation({
  mutationKey: ["set-device-main-group", deviceGroupID],
  mutationFn: (deviceID) => DeviceGroupRequests.setMainDeviceGroupOfDevice(deviceGroupID, deviceID),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["device-group", deviceGroupID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useUpdateDeviceGroupControlAlgorithmConfigs = (deviceGroupID: string, options?: UseMutationOptions<LandfillControlAlgorithmConfigs, Error, LandfillControlAlgorithmConfigs>) => useMutation({
  mutationKey: ["update-device-group-ca-configs", deviceGroupID],
  mutationFn: (body) => DeviceGroupRequests.updateDeviceGroupControlAlgorithmConfigs(deviceGroupID, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["device-group", deviceGroupID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});
